import { defineStore } from 'pinia'

interface ImageViewerOptions {
  images?: string[]
}

interface ImageViewerCallback {
  options: ImageViewerOptions
  onClose: (result?: boolean) => void
}

export const useImageViewerStore = defineStore({
  id: 'imageViewer',
  state: () => ({
    dialogCallback: null as ImageViewerCallback | null,
  }),
  getters: {
    options: (state) => state.dialogCallback?.options,
  },
  actions: {
    setImageViewerCallback(ImageViewer: ImageViewerCallback | null) {
      this.dialogCallback = ImageViewer
    },
    open(options: ImageViewerOptions) {
      if (this.dialogCallback) {
        this.dialogCallback.onClose(false)
      }
      return new Promise<boolean | string>((resolve) => {
        this.setImageViewerCallback({
          options,
          onClose: resolve,
        } as ImageViewerCallback)
      })
    },
    close(confirmed?: boolean) {
      if (this.dialogCallback) {
        this.dialogCallback.onClose(confirmed)
      }
      this.setImageViewerCallback(null)
    },
  },
})
